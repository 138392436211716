<div class="page-container auth-page-center">
  <app-sign-up-step0
    [class.display-none]="step !== 0"
    [email]="email"
    [phone]="phone"
    [hasNoAccount]="hasNoAccount"
    [selectedOption]="selectedOption"
    (selectOptionEvent)="selectOption($event)"
    (emailEntered)="onEmailEntered($event)"
    (phoneEntered)="onPhoneEntered($event)"
    class="auth-step-page-center"
  >
  </app-sign-up-step0>
  <app-sign-up-step1
    [class.display-none]="step !== 1"
    [selectedOption]="selectedOption"
    [phone]="phone"
    [email]="email"
    [signUpIsInProgress]="signUpIsInProgress"
    (next)="signUpFromForm($event)"
    class="auth-step-page-center"
  >
  </app-sign-up-step1>
  <app-sign-up-referral-step
    [class.display-none]="step !== 2"
    [referralCode]="referralCode"
    [signUpIsInProgress]="signUpIsInProgress"
    [isWrongCode]="isWrongCode"
    (next)="signUpWReferral($event)"
    class="auth-step-page-center"
  >
  </app-sign-up-referral-step>

  @if (selectedOption === "email") {
    <app-verify-email-step
      [class.display-none]="step !== 3"
      [userId]="userId"
      [email]="email"
      (verifyEmailSuccess)="onVerifyEmailSuccess()"
      class="auth-step-page-center"
    >
    </app-verify-email-step>
  } @else if (selectedOption === "phone") {
    <app-verify-phone-step
      [class.display-none]="step !== 3"
      [userId]="userId"
      [phone]="phone"
      [isWrongCode]="isWrongCode"
      (changePhone)="changePhone()"
      (verifyPhoneSuccess)="onVerifyPhoneSuccess($event)"
      class="auth-step-page-center"
    >
    </app-verify-phone-step>
  }

  <!--  <app-set-phone-step-->
  <!--    [class.display-none]="step !== 3"-->
  <!--    [userId]="userId"-->
  <!--    (enterPhoneRes)="onEnterPhoneRes($event)"-->
  <!--    class="auth-step-page-center"-->
  <!--  >-->
  <!--  </app-set-phone-step>-->

  @if (step === 2 && signUpIsInProgress) {
    <div class="full-page-loader-cont">
      <div class="loader"></div>
    </div>
  }
</div>
