import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventCategory } from '@models/event-categories/event-category.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventCategoriesService } from '@services/event-categories.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { SharedService } from '@services/shared/shared.service';
import { Asset, getAssetFromURL } from '@models/shared/asset.model';
import { CustomDialogData } from '@models/overlay-dialogs/custom-dialog-data.model';
import { CustomDialogComponent } from '@modules/shared/dialogs/custom-dialog/custom-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModules } from '@utils/shared-modules';
import { FileUploaderComponent } from '@modules/shared/components/file-uploader/file-uploader.component';
import { TargetGroup } from '@models/shared/target-group.model';
import { Campaign } from '@models/campaigns/campaign.model';
import { CampaignService } from '@services/campaign.service';
import { SaveCampaignRequest } from '@models/campaigns/dto/save-campaign.request';
import { CampaignStatus } from '@models/campaigns/campaign-status.enum';
import { AutocompleteSelectComponent } from '@modules/shared/components/autocomplete-select/autocomplete-select.component';

@Component({
  selector: 'app-campaign-overlay-page',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    FileUploaderComponent,
    AutocompleteSelectComponent,
  ],
  providers: [CampaignService, EventCategoriesService, SharedService],
  templateUrl: './campaign-overlay-page.component.html',
  styleUrl: './campaign-overlay-page.component.scss',
})
export class CampaignOverlayPageComponent {
  isEdit: boolean = false;

  allCategories: EventCategory[] = [];
  categories: EventCategory[] = [];
  subcategories: EventCategory[] = [];
  campaignStatuses = CampaignStatus.getAllCampaignStatuses();
  targetGroups: TargetGroup[] = [];

  campaign?: Campaign;

  form?: FormGroup;

  constructor(
    private campaignService: CampaignService,
    private eventCategoriesService: EventCategoriesService,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<CampaignOverlayPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) {
    if (data) {
      this.isEdit = data.isEdit;
      this.campaign = data.campaign;
    }

    this.sharedService.getTargetGroups().subscribe((targetGroups) => {
      this.targetGroups = targetGroups;

      if (data?.allCategories !== undefined) {
        this.allCategories = data.allCategories;

        this.filterAndSortCategoriesAndSubcategories();
      } else {
        this.eventCategoriesService
          .getEventCategories()
          .subscribe((eventCategories) => {
            this.allCategories = eventCategories;

            this.filterAndSortCategoriesAndSubcategories();
          });
      }
    });
  }

  initForm(): void {
    let logoAsset = undefined;
    if (this.campaign?.logoUrl && this.campaign?.logoUrl !== '') {
      logoAsset = getAssetFromURL(this.campaign?.logoUrl);
    }

    this.form = this.fb.group({
      name: [this.campaign?.name || '', Validators.required],
      uri: [this.campaign?.uri || '', Validators.required],
      status: [this.campaign?.status || '', Validators.required],
      logoAsset: [logoAsset, Validators.required],

      enteringScreenTitle: [
        this.campaign?.enteringScreenTitle || '',
        Validators.required,
      ],
      welcomeScreenHtml: [
        this.campaign?.welcomeScreenHtml || '',
        Validators.required,
      ],

      category: [this.campaign?.getEventParentCategoryId() || null],
      subcategory: [this.campaign?.getEventSubCategoryId() || null],

      targetGroup: [this.campaign?.preselectedTargetGroupId || null],

      allCampaignUsersListId: [this.campaign?.allCampaignUsersListId || ''],
      campaignAdsUsersListId: [this.campaign?.campaignAdsUsersListId || ''],

      accountCreationForVoting: [this.campaign?.accountCreationForVoting],
    });

    this.form.controls['category'].valueChanges.subscribe((val) => {
      this.onCategoryChange(val);
    });
  }

  filterAndSortCategoriesAndSubcategories() {
    this.categories = this.allCategories
      ?.filter((item: EventCategory) => !item.parentCategoryId)
      .sort((a, b) => a.name.localeCompare(b.name));
    this.subcategories = this.allCategories
      ?.filter((item: EventCategory) => item.parentCategoryId)
      .sort((a, b) => a.name.localeCompare(b.name));

    this.initForm();

    this.onCategoryChange(this.campaign?.getEventParentCategoryId());
  }

  onCategoryChange(val?: number) {
    if (val) {
      this.subcategories = this.allCategories
        .filter((item: EventCategory) => item.parentCategoryId === val)
        .sort((a, b) => a.name.localeCompare(b.name));
      let selectedSubcategory: number = this.form!.value.subcategory;

      if (selectedSubcategory) {
        if (
          !this.subcategories
            .map((category) => category.id)
            .includes(selectedSubcategory)
        ) {
          this.form!.controls['subcategory'].patchValue('');
        }
      }
    } else {
      this.subcategories = this.allCategories
        .filter((item: EventCategory) => item.parentCategoryId)
        .sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  createOrUpdate() {
    if (this.form && this.isFormValid()) {
      const value = this.form.value;

      const saveCampaignReq: SaveCampaignRequest = {
        name: value.name,
        uri: value.uri,

        status: value.status,

        logoAsset: value.logoAsset,

        enteringScreenTitle: value.enteringScreenTitle,
        welcomeScreenHtml: value.welcomeScreenHtml,

        preselectedEventCategoryId: value.subcategory
          ? value.subcategory
          : value.category,
        preselectedTargetGroupId: value.targetGroup,

        allCampaignUsersListId: value.allCampaignUsersListId,
        campaignAdsUsersListId: value.campaignAdsUsersListId,

        accountCreationForVoting: value.accountCreationForVoting,
      };

      if (this.isEdit) {
        saveCampaignReq.id = this.campaign?.id;
      }

      this.campaignService
        .saveCampaign(saveCampaignReq)
        .subscribe((campaign) => {
          const confirmation =
            'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.PRODUCT_CONFIRMATION_MESSAGE';
          const okButtonMessage =
            'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTION_BUTTONS.OK';
          const title =
            'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.CONFIRMATION_TITLE';

          const data: CustomDialogData = {
            confirmation: confirmation,
            title: title,
            cancelButtonMessage: okButtonMessage,
            id: campaign.id,
            pictureUrl: campaign.logoUrl,
          };

          this.dialog.open(CustomDialogComponent, {
            maxWidth: '455px',
            width: '100%',
            height: 'auto',
            data: data,
          });

          this.dialogRef.close({
            campaign: campaign,
            refresh: true,
          });
        });
    }
  }

  isFormValid(): boolean {
    this.form!.markAllAsTouched();

    return this.form!.valid;
  }

  getDisplayStatus(status: CampaignStatus) {
    return CampaignStatus.getDisplayName(status);
  }

  onAssetChanged(asset: Asset, assetName: 'logoAsset') {
    this.form!.controls[`${assetName}`].patchValue(asset);
  }
}
