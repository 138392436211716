import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes } from 'app/routes';
import { LoginFormComponent } from '@modules/auth/components/login-form/login-form.component';
import { SsoButtonsComponent } from '@modules/auth/components/sso-buttons/sso-buttons.component';
import { HCaptchaInfoComponent } from '@modules/shared/components/h-captcha-info/h-captcha-info.component';
import { VerifyPhoneDialogComponent } from '@modules/events/dialogs/verify-phone-dialog/verify-phone-dialog.component';
import { LoginRequest } from '@models/users/dto/login.request';
import { AuthService } from '@services/auth/auth.service';
import { NotificationService } from '@services/shared/notification.service';
import { LoginStep2Component } from '@modules/auth/components/login-step2/login-step2.component';
import { BackService } from '@services/shared/back.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { finalize, Subject } from 'rxjs';
import { UserService } from '@services/auth/user.service';
import { NewUserCreationRequest } from '@models/users/dto/new-user-creation.request';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    LoginFormComponent,
    SsoButtonsComponent,
    HCaptchaInfoComponent,
    VerifyPhoneDialogComponent,
    LoginStep2Component,
  ],
  providers: [NotificationService, TranslateService, UserService],
  templateUrl: './login.page.html',
  styleUrl: './login.page.scss',
})
export class LoginPage implements OnInit, OnDestroy {
  step: number = 0;
  userPhone: string = '';
  userEmail: string = '';
  selectedOption: string = 'phone';
  isWrongCode: boolean = false;
  otpUserId?: number;
  password?: string;
  loginIsInProgress: boolean = false;

  loginInProgress = false;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private backService: BackService,
    private userService: UserService,
  ) {
    const snapshot = this.activatedRoute.snapshot;

    if (snapshot.queryParamMap.has('option')) {
      const option = snapshot.queryParamMap.get('option');
      if (option && option !== '' && ['email', 'phone'].includes(option)) {
        this.selectedOption = option;
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { option: null },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      }
    }

    if (snapshot.queryParamMap.has('phone')) {
      const userPhone = snapshot.queryParamMap.get('phone');
      if (userPhone && userPhone !== '') {
        this.userPhone = userPhone;
        this.selectedOption = 'phone';
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { phone: null },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });

        const createOrFindUserReq: NewUserCreationRequest = {
          phone: userPhone,
        };
        this.userService.findUser(createOrFindUserReq).subscribe({
          next: (userId: number) => {
            this.otpUserId = userId;
            this.step++;
            this.backService.showBackButton(true);
          },
          error: () => {},
        });
      }
    }
  }

  ngOnInit(): void {
    this.backService.backButtonClick$
      .pipe(takeUntil(this._onDestroy))
      .subscribe((clicked) => {
        if (clicked && this.step === 1) {
          this.step--;
          this.backService.showBackButton(false);
        }
      });
  }

  goToForgotPassword(): void {
    this.router.navigate(['/', AppRoutes.Root.forgotPassword]);
  }

  goToSignUp(): void {
    this.router.navigate(['/', AppRoutes.Root.signUp]);
  }

  setStep(event: any) {
    this.selectedOption = event.selectedOption;
    const hasNoAccount = event.hasNoAccount;

    if (event.selectedOption && event.selectedOption === 'email') {
      this.userEmail = event.userEmail;

      if (hasNoAccount) {
        this.router.navigate(['/', AppRoutes.Root.signUp], {
          queryParams: { email: this.userEmail },
        });
      }
    } else if (event.selectedOption && event.selectedOption === 'phone') {
      this.userPhone = event.userPhone;

      if (hasNoAccount) {
        this.router.navigate(['/', AppRoutes.Root.signUp], {
          queryParams: { phone: this.userPhone },
        });
      }
    }

    if (!hasNoAccount) {
      if (event.otpUserId) {
        this.otpUserId = event.otpUserId;
      }

      this.step++;
      this.backService.showBackButton(true);
    }
  }

  authLoginEvent(event: any) {
    if (event.logUser) {
      this.authLogin(event.loginRequest);
    }
  }

  changePhone() {
    this.step = 0;
    this.backService.showBackButton(false);
  }
  authLogin(loginReq: LoginRequest) {
    const campaignUri = localStorage.getItem('last-campaign-before-login');
    const eventUri = localStorage.getItem('last-campaign-event-before-login');

    if (loginReq.phone && loginReq.otp && !this.loginInProgress) {
      this.loginInProgress = true;
      this.authService.loginWithOtp(
        loginReq,
        !(campaignUri && eventUri),
        () => {
          this.loginInProgress = false;
          if (
            campaignUri &&
            campaignUri !== '' &&
            eventUri &&
            eventUri !== ''
          ) {
            this.router.navigate(['/', 'c', campaignUri, 'voting', eventUri], {
              replaceUrl: true,
            });

            localStorage.removeItem('last-campaign-before-login');
            localStorage.removeItem('last-campaign-event-before-login');
          }
        },
        (denied: any) => {
          this.isWrongCode = true;
          this.notificationService.error(
            this.translateService.instant('APP.AUTH.ERRORS.INVALID_CODE'),
          );
          this.loginInProgress = false;
        },
      );
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
