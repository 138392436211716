import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import {
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
} from '@angular/router';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Helpers } from '@utils/helpers';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuRoute } from '@models/sidenav/menu-route.model';
import { User } from '@models/users/user.model';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { AuthService } from '@services/auth/auth.service';
import { SidenavService } from '@services/shared/sidenav.service';
import { MatSidenavModule, MatSidenav } from '@angular/material/sidenav';
import { UserRole } from '@models/users/user-role.enum';
import {
  ADMIN_NAV_ROUTES,
  B2B_CUSTOMER_NAV_ROUTES,
  CUSTOMER_NAV_ROUTES,
  publicRoutes,
} from './app.menu';
import { InternalSidenavComponent } from './components/internal-sidenav/internal-sidenav.component';
import { InternalHeaderComponent } from './components/internal-header/internal-header.component';
import { AppRoutes } from './routes';
import { InternalCustomerHeaderComponent } from './components/internal-customer-header/internal-customer-header.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { DateAdapter } from '@angular/material/core';
import { environment as env } from '@environments/environment';
import { MetadataService } from '@services/metadata.service';
import { ReferralInviteDialog } from '@modules/customer/dialogs/referral-invite/referral-invite.dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterModule,
    TranslateModule,
    MatSidenavModule,
    HeaderComponent,
    FooterComponent,
    InternalSidenavComponent,
    InternalHeaderComponent,
    InternalCustomerHeaderComponent,
  ],
  providers: [SidenavService, ScreenWidthService],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'eventpageai-frontend';

  private readonly headerHeightInPixels = 92;

  @ViewChild('sidenav') public sidenav?: MatSidenav;

  externalNavRoutes = [];
  navRoutes: MenuRoute[] = [];

  floatingSupportOpened = false;

  isCustomer = false;
  isAdmin = false;
  internalRoutes: MenuRoute[] = [];

  get isAdminOrCustomer() {
    return this.isAdmin || this.isCustomer;
  }

  isPublicRoute = true;
  isDesktop?: boolean;

  private user?: User;

  isPublicMenu = true;

  isCreateEventPage = false;
  isEventPage = false;
  isCampaignEventVotingPage = false;
  isDesignTemplatePreviewPage = false;
  isLinkBioPage = false;
  isLandingPage = false;
  isTermsOfUsePage = false;

  isPageWithGenericHeader = false;
  isPageWithGenericFooter = false;
  isPageWithoutSideItems = false;

  constructor(
    private authService: AuthService,
    private sidenavService: SidenavService,
    private router: Router,
    private screenWidthService: ScreenWidthService,
    private viewportScroller: ViewportScroller,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private gtmService: GoogleTagManagerService,
    private dateAdapter: DateAdapter<any>,
    private metadataService: MetadataService,
    private dialog: MatDialog,
  ) {
    this.translate.setDefaultLang('en');
    this.translate.addLangs(['en', 'de']);

    this.setupPlatform();

    this.setUserTheme();

    this.trackIsDesktopChange();

    // subscribe to user object
    this.authService.userSubject.subscribe((user) => {
      this.user = user;
      this.isCustomer = user.isCustomer();
      this.isAdmin = user.isAdmin();
      this.refreshSidenavOpenStatus();
      this.initializeNavLinks();
      this.setUserLanguage();
      this.setUserTheme();
    });

    this.trackRouteChanges();
  }

  private setUserLanguage() {
    if (this.user && this.user.id > 0) {
      let userLang = this.user.language;
      if (!userLang || userLang === '') {
        let userLang = localStorage.getItem('userLang');
        if (!userLang) {
          const browserLang = this.translate.getBrowserLang();
          userLang = browserLang || 'en';
          localStorage.setItem('userLang', userLang);
        }

        if (!this.user.language || this.user.language === '') {
          this.authService
            .updateUser({
              id: this.user.id,
              language: 'de',
            })
            .subscribe();
        }
      }

      const langToUse = userLang && userLang.match(/en|de/) ? userLang : 'en';

      this.translate.use(langToUse);
      this.dateAdapter.setLocale(langToUse);
    } else {
      let userLang = localStorage.getItem('userLang');
      if (!userLang) {
        const browserLang = this.translate.getBrowserLang();
        userLang = browserLang || 'en';
        localStorage.setItem('userLang', userLang);
      }

      const langToUse = userLang && userLang.match(/en|de/) ? userLang : 'en';

      this.translate.use(langToUse);
      this.dateAdapter.setLocale(langToUse);
    }
  }

  private setUserTheme() {
    if (this.isAdmin) {
      localStorage.setItem('userTheme', 'light');
    }

    Helpers.setTheme();
  }

  private setupPlatform() {
    if (
      env.production &&
      window &&
      window.location &&
      window.location.hostname
    ) {
      const favIcon: any = document.querySelector('#favIcon');

      if (window.location.hostname === 'app.partypage.ai') {
        env.website = 'https://app.partypage.ai';
        env.assets = 'https://app.partypage.ai';
        env.logoAsset = './assets/logo-partypage.png';
        env.iconAsset = './assets/icon-partypage.svg';
        env.platform = 'partypage.ai';
        env.platformShort = 'partypage';
        if (!localStorage.getItem('userTheme')) {
          localStorage.setItem('userTheme', 'dark');
        }
        favIcon.href = 'favicon-partypage.ico';
      } else {
        env.website = 'https://app.eventpage.ai';
        env.assets = 'https://app.eventpage.ai';
        env.logoAsset = './assets/logo.svg';
        env.iconAsset = './assets/icon.svg';
        env.platform = 'eventpage.ai';
        env.platformShort = 'eventpage';
        if (!localStorage.getItem('userTheme')) {
          localStorage.setItem('userTheme', 'light');
        }
        favIcon.href = 'favicon.ico';
      }
    }
  }

  private trackIsDesktopChange() {
    this.screenWidthService.isDesktop().subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
      this.refreshSidenavOpenStatus();
    });
  }

  private trackRouteChanges() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isCreateEventPage =
          event.url.startsWith(`/${AppRoutes.Events.createEvent}`) ||
          event.url.startsWith(`/${AppRoutes.Events.create}`);
        this.isEventPage = event.url.startsWith(`/${AppRoutes.Events.events}`);
        this.isCampaignEventVotingPage =
          event.url.startsWith('/c') && event.url.includes('/voting/');
        this.isDesignTemplatePreviewPage = event.url.startsWith(
          `/${AppRoutes.DesignTemplates.designTemplates}`,
        );
        this.isLinkBioPage = event.url.startsWith(
          `/${AppRoutes.Customer.eventLink}`,
        );

        this.isLandingPage = event.url.startsWith(`/landing`);
        this.isTermsOfUsePage = event.url.startsWith('/mizuno-terms-of-use');

        const isPublicPage =
          publicRoutes.findIndex(
            (it) =>
              event.url.startsWith(it) ||
              event.urlAfterRedirects.startsWith(it),
          ) !== -1;
        this.isPageWithGenericHeader =
          isPublicPage &&
          !this.isEventPage &&
          !this.isCampaignEventVotingPage &&
          !this.isLinkBioPage &&
          !this.isLandingPage &&
          !this.isTermsOfUsePage;
        this.isPageWithGenericFooter =
          isPublicPage &&
          !this.isCreateEventPage &&
          !this.isDesignTemplatePreviewPage;
        this.isPageWithoutSideItems =
          isPublicPage ||
          this.isEventPage ||
          this.isCampaignEventVotingPage ||
          this.isDesignTemplatePreviewPage ||
          this.isLandingPage ||
          this.isTermsOfUsePage;

        this.changeDetectorRef.detectChanges();
        if (this.sidenav) {
          this.sidenavService.setSidenav(this.sidenav);
        }
        this.refreshSidenavOpenStatus();

        // reset the view to the top on each route change
        if (window.scrollTo) {
          window.scrollTo(0, 0);
        }

        this.checkIsPublicRoute(event.urlAfterRedirects);

        if (window && window.location && window.location.hash) {
          let offset = this.headerHeightInPixels;
          if (!this.isDesktop) {
            offset += 300;
          }
          this.viewportScroller.setOffset([0, offset]);
          this.viewportScroller.scrollToAnchor(
            window.location.hash.substring(1),
          );
        }

        if (
          !this.isEventPage &&
          !this.isCampaignEventVotingPage &&
          !this.isLinkBioPage &&
          !this.isDesignTemplatePreviewPage &&
          !this.isLandingPage
        ) {
          this.setDefaultMetaTags();
        }

        // google tag manager push
        this.gtmService.pushTag({
          event: 'page',
          pageName: event.url,
        });
      }
    });
  }

  public ngOnInit(): void {
    // store sidenav to service
    if (this.sidenav) {
      this.sidenavService.setSidenav(this.sidenav);
    }
  }

  openReferralInviteDialog(): void {
    this.dialog.open(ReferralInviteDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        loggedUser: this.user,
      },
      panelClass: ['normal-dialog', 'opacity-dialog'],
    });
  }

  // initialize correct routes based on user role
  private initializeNavLinks = () => {
    if (!this.user) {
      return;
    }

    this.isPublicMenu = false;
    switch (this.user.role) {
      case UserRole.ADMIN:
        this.internalRoutes = ADMIN_NAV_ROUTES;
        this.navRoutes = [];
        this.externalNavRoutes = [];
        break;
      case UserRole.CUSTOMER:
        this.internalRoutes = this.user.isB2B
          ? B2B_CUSTOMER_NAV_ROUTES
          : CUSTOMER_NAV_ROUTES;
        this.navRoutes = [];
        this.externalNavRoutes = [];
        break;
      default:
        this.isPublicMenu = true;
        this.externalNavRoutes = [];
        this.navRoutes = [];
    }
  };

  private checkIsPublicRoute(route: string) {
    this.isPublicRoute =
      publicRoutes.findIndex((it) => route.startsWith(it)) !== -1;
    this.refreshSidenavOpenStatus();
  }

  private refreshSidenavOpenStatus() {
    if (this.isSideMode && this.sidenav) {
      this.sidenav.open();
    } else if (!this.isDesktop && this.isCustomer && this.sidenav) {
      this.sidenav.close();
    }
  }

  get isSideMode() {
    return this.isDesktop && (this.isAdmin || this.isCustomer);
  }

  private setDefaultMetaTags() {
    this.metadataService.updateMetadata({});
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(evt: KeyboardEvent) {
    return this.disableBackNavigationOnBackspacePress(evt);
  }

  private disableBackNavigationOnBackspacePress(evt: KeyboardEvent) {
    if (evt.key === 'Backspace') {
      let doPrevent = true;
      const types = [
        'text',
        'password',
        'file',
        'search',
        'email',
        'number',
        'date',
        'color',
        'datetime',
        'datetime-local',
        'month',
        'range',
        'search',
        'tel',
        'time',
        'url',
        'week',
      ];
      const target = <HTMLInputElement>evt.target;

      const disabled =
        target.disabled || (<HTMLInputElement>evt.target).readOnly;
      if (!disabled) {
        if (target.isContentEditable) {
          doPrevent = false;
        } else if (target.nodeName === 'INPUT') {
          let type = target.type;
          if (type) {
            type = type.toLowerCase();
          }
          if (types.indexOf(type) > -1) {
            doPrevent = false;
          }
        } else if (target.nodeName === 'TEXTAREA') {
          doPrevent = false;
        }
      }
      if (doPrevent) {
        evt.preventDefault();
        return false;
      }
    }
    return;
  }
}
