<div class="list-page-container">
  <div>
    <h1 class="page-header">{{ "APP.USERS.LIST_HEADER" | translate }}</h1>

    <hr class="hr" />
  </div>

  <div>
    <div>
      <app-filter
        [isUserListPage]="true"
        (filterChange)="onFilterChange($event)"
      ></app-filter>
    </div>

    <div class="table-scrollable" [class.loading]="dataSource.loading$ | async">
      <mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="masterToggle()"></mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox (change)="toggleSelection(row)"></mat-checkbox>
          </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >{{ 'APP.DESIGN_TEMPLATES.TABLE_HEADERS.NAME' | translate
            }}</mat-header-cell
          >
          <mat-cell *matCellDef="let user">
            <div class="name-and-picture-col">
              <img
                *ngIf="user.avatarUrl"
                [src]="user.avatarUrl"
                alt="User Avatar"
              />
              <div class="ellipsis-field">{{ user.name }}</div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >{{ 'APP.DESIGN_TEMPLATES.TABLE_HEADERS.ID' | translate
            }}</mat-header-cell
          >
          <mat-cell *matCellDef="let user">{{ user.id }}</mat-cell>
        </ng-container>

        <!-- Creation Date Column -->
        <ng-container matColumnDef="creationDate">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >{{ 'APP.CREATION_DATE' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let user"
            >{{ user.createdAt.getTime() > 0 ? (user.createdAt | date:
            'dd.MM.yyy') : 'n/a' }}</mat-cell
          >
        </ng-container>

        <!-- E-Mail Column -->
        <ng-container matColumnDef="email">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >{{ 'APP.USERS.EMAIL' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let user">{{ user.email || '-' }} </mat-cell>
        </ng-container>

        <!-- E-Mail verified Column -->
        <ng-container matColumnDef="emailVerified">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >{{ 'APP.USERS.EMAIL_VERIFIED' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let user"
            >{{ (user.emailVerified ? 'APP.YES' : 'APP.NO') | translate }}
          </mat-cell>
        </ng-container>

        <!-- Phone Column -->
        <ng-container matColumnDef="phone">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >{{ 'APP.USERS.PHONE' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let user">{{ user.phone || '-' }} </mat-cell>
        </ng-container>

        <!-- Phone verified Column -->
        <ng-container matColumnDef="phoneVerified">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >{{ 'APP.USERS.PHONE_VERIFIED' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let user"
            >{{ (user.phoneVerified ? 'APP.YES' : 'APP.NO') | translate }}
          </mat-cell>
        </ng-container>

        <!-- Role Column -->
        <ng-container matColumnDef="role">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >{{ 'APP.USERS.ROLE' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let user"
            >{{ UserRole.getDisplayName(user.role) | translate }}
          </mat-cell>
        </ng-container>

        <!-- Use Case Column -->
        <ng-container matColumnDef="useCase">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >{{ 'APP.USERS.USE_CASE' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let user"
            >{{ user.useCase ? ('APP.USE_CASE.' + user.useCase | translate) :
            '-' }}
          </mat-cell>
        </ng-container>

        <!-- Hosting Events Column -->
        <ng-container matColumnDef="numHostingEvents">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >{{ 'APP.USERS.NUM_HOSTING_EVENTS' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let user"
            >{{ user.numHostingEvents }}
          </mat-cell>
        </ng-container>

        <!-- Co-Hosting Events Column -->
        <ng-container matColumnDef="numCoHostingEvents">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >{{ 'APP.USERS.NUM_COHOSTING_EVENTS' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let user"
            >{{ user.numCoHostingEvents }}
          </mat-cell>
        </ng-container>

        <!-- Attending Events Column -->
        <ng-container matColumnDef="numAttendingEvents">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >{{ 'APP.USERS.NUM_ATTENDING_EVENTS' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let user"
            >{{ user.numAttendingEvents }}
          </mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions" stickyEnd>
          <mat-header-cell *matHeaderCellDef
            >{{ 'APP.DESIGN_TEMPLATES.TABLE_HEADERS.ACTIONS' | translate
            }}</mat-header-cell
          >
          <mat-cell *matCellDef="let event">
            <button
              mat-icon-button
              class="more-vert-rotate-90"
              (click)="$event.stopPropagation()"
              disabled
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          [hidden]="dataSource.loading$ | async"
          *matRowDef="let row; columns: displayedColumns;"
          (click)="onRowClick(row)"
        ></mat-row>

        <div
          class="spinner-container d-flex justify-content-center"
          *ngIf="dataSource.loading$ | async"
        >
          <mat-spinner></mat-spinner>
        </div>
      </mat-table>
    </div>

    <app-paginator
      [totalItems]="dataSource.numRecords$ | async"
      [pageSizeOptions]="pageSizeOptions"
      (changePage)="onPageChange($event)"
    >
    </app-paginator>
  </div>
</div>
