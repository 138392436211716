<div class="header">
  <app-back-button class="mobile-only" [size]="35"></app-back-button>
  <div class="flex-left-placeholder desktop-only"></div>
  <h1 mat-dialog-title class="title" *ngIf="!isEdit">
    {{ "APP.DESIGN_TEMPLATES.CREATE.TITLE" | translate }}
  </h1>
  <h1 mat-dialog-title class="title" *ngIf="isEdit">
    {{ "APP.DESIGN_TEMPLATES.CREATE.TITLE_EDIT" | translate }}
  </h1>
  <i class="fal fa-times-circle" (click)="cancel()"></i>
</div>
<app-progress-indicator
  [currentStep]="currentStep"
  [numberOfSteps]="numberOfSteps"
>
</app-progress-indicator>
<div class="create-step-header">
  <div class="flex-left-placeholder desktop-only">
    @if (currentStep > 1) {
      <app-back-button></app-back-button>
    }
  </div>

  <div class="header-title-cont">
    <div class="header-info">
      {{ "APP.STEP" | translate }} {{ currentStep }} /
      {{ numberOfSteps }}
    </div>
    <h1>{{ stepTitle | translate }}</h1>
  </div>
  <button type="button" class="btn btn-primary" (click)="goToNextStep()">
    {{ nextLabel | translate }}
  </button>
</div>
