<form [formGroup]="form">
  <h2 class="body-header">
    {{ "APP.DESIGN_TEMPLATES.CREATE.URI" | translate }}*
  </h2>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>{{ "APP.DESIGN_TEMPLATES.CREATE.URI" | translate }}</mat-label>
    <input
      name="uri"
      type="text"
      matInput
      formControlName="uri"
      placeholder=""
      [readonly]="access === EventTemplateAccess.PUBLIC"
    />
    @if (access === EventTemplateAccess.PUBLIC) {
      <div
        matSuffix
        class="icon-suffix"
        matTooltip="{{
          'APP.DESIGN_TEMPLATES.CREATE.URI_CHANGE_INFO' | translate
        }}"
        matTooltipPosition="above"
      >
        <i class="fal fa-info-circle"></i>
      </div>
    }
  </mat-form-field>

  <h2 class="body-header">
    {{ "APP.DESIGN_TEMPLATES.CREATE.SHOWN_ON_SITE" | translate }}*
  </h2>
  <div class="toggle-container">
    <label class="toggle-check">
      <input
        type="checkbox"
        class="toggle-check-input"
        formControlName="shownOnSite"
      />
      <span class="toggle-check-text"></span>
    </label>
  </div>

  <div class="body-header-cont">
    <h2 class="body-header">
      {{ "APP.DESIGN_TEMPLATES.CREATE.SEO_SHARING_PICTURE" | translate }}*
    </h2>
    <div class="body-header-info">(1200 x 628 px)</div>
  </div>
  <div class="file-container">
    <app-file-uploader
      [aspectRatio]="300 / 157"
      [fluidWidth]="true"
      [resizeToWidth]="1200"
      [useImagePreviewComponent]="true"
      [uploaderType]="'IMAGE'"
      [selectedFile]="form.controls['seoSharingPictureAsset'].value"
      [invalid]="
        form.controls['seoSharingPictureAsset'].touched &&
        form.controls['seoSharingPictureAsset'].invalid
      "
      (fileChanged)="onImageChanged($event)"
    >
    </app-file-uploader>
  </div>

  @if (form.controls["seoSharingPictureAsset"].value) {
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{ "APP.UPLOADER.FILE_NAME" | translate }}*</mat-label>
      <input
        #seoPictureFileName
        type="text"
        matInput
        formControlName="seoPictureFileName"
        maxlength="65"
      />
      <span matTextSuffix
        >{{ seoPictureFileName.value.length || "" }} | 65</span
      >
      <div
        matSuffix
        class="icon-suffix"
        matTooltip="{{ 'APP.UPLOADER.FILE_NAME_INFO' | translate }}"
        matTooltipPosition="above"
      >
        <i class="fal fa-info-circle"></i>
      </div>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label
        >{{
          "APP.DESIGN_TEMPLATES.CREATE.SEO_SHARING_PICTURE_TITLE" | translate
        }}*</mat-label
      >
      <input
        #seoPictureTitle
        type="text"
        matInput
        formControlName="seoPictureTitle"
        maxlength="65"
      />
      <span matTextSuffix>{{ seoPictureTitle.value.length || "" }} | 65</span>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label
        >{{
          "APP.DESIGN_TEMPLATES.CREATE.SEO_SHARING_PICTURE_ALT" | translate
        }}*</mat-label
      >
      <input
        #seoPictureAlt
        type="text"
        matInput
        formControlName="seoPictureAlt"
        maxlength="100"
      />
      <span matTextSuffix>{{ seoPictureAlt.value.length || "" }} | 70-100</span>
      <div
        matSuffix
        class="icon-suffix"
        matTooltip="{{ 'APP.UPLOADER.ALT_INFO' | translate }}"
        matTooltipPosition="above"
      >
        <i class="fal fa-info-circle"></i>
      </div>
    </mat-form-field>
  }

  <h2 class="body-header">
    {{ "APP.DESIGN_TEMPLATES.CREATE.SEO_TITLE" | translate }}*
  </h2>
  <mat-form-field appearance="outline" class="w-100">
    <input
      #seoTitle
      type="text"
      matInput
      formControlName="seoTitle"
      maxlength="65"
      placeholder="{{ 'APP.DESIGN_TEMPLATES.CREATE.SEO_TITLE' | translate }}"
    />
    <span matTextSuffix>{{ seoTitle.value.length || "" }} | 65</span>
  </mat-form-field>

  <h2 class="body-header">
    {{ "APP.DESIGN_TEMPLATES.CREATE.SEO_DESCRIPTION" | translate }}*
  </h2>
  <mat-form-field appearance="outline" class="w-100">
    <textarea
      #seoDescription
      matInput
      formControlName="seoDescription"
      rows="5"
      maxlength="160"
      placeholder="{{
        'APP.DESIGN_TEMPLATES.CREATE.SEO_DESCRIPTION' | translate
      }}"
    ></textarea>
    <span matTextSuffix>{{ seoDescription.value.length || "" }} | 160</span>
  </mat-form-field>

  <h2 class="body-header">
    {{ "APP.DESIGN_TEMPLATES.CREATE.SEO_PAGE_TITLE" | translate }}
  </h2>
  <mat-form-field appearance="outline" class="w-100">
    <input
      type="text"
      matInput
      formControlName="seoPageTitle"
      placeholder="{{
        'APP.DESIGN_TEMPLATES.CREATE.SEO_PAGE_TITLE' | translate
      }}"
    />
  </mat-form-field>

  <h2 class="body-header">
    {{ "APP.DESIGN_TEMPLATES.CREATE.SEO_PAGE_DETAIL_SUMMARY" | translate }}
  </h2>
  <mat-form-field appearance="outline" class="w-100">
    <textarea
      matInput
      formControlName="seoPageDetailSummary"
      rows="5"
      placeholder="{{
        'APP.DESIGN_TEMPLATES.CREATE.SEO_PAGE_DETAIL_SUMMARY' | translate
      }}"
    ></textarea>
  </mat-form-field>

  <h2 class="body-header">
    {{ "APP.DESIGN_TEMPLATES.CREATE.SEO_PAGE_DETAIL" | translate }}
  </h2>
  <app-rich-text-editor
    [adminView]="true"
    [valueToChange]="form.controls['seoPageDetail'].value"
    (updateValue)="saveEditorContent($event)"
  />

  <h2 class="body-header">{{ "APP.CHOOSE_CATEGORY" | translate }}*</h2>
  @if (categories && categories.length > 0) {
    <app-autocomplete-select
      [form]="form"
      selectedFormControlName="category"
      [items]="categories"
      labelProperty="title"
      valueProperty="id"
      [useValueProperty]="true"
    >
    </app-autocomplete-select>
  }

  <h2 class="body-header">{{ "APP.ASSIGN_KEYWORDS" | translate }}*</h2>
  <app-keywords-select
    [form]="form"
    selectedFormControlName="keywords"
    [items]="keywords"
  ></app-keywords-select>

  <h2 class="body-header">
    {{ "APP.DESIGN_TEMPLATES.CREATE.MARKETPLACE_VISUAL" | translate }}
  </h2>
  <div class="file-container">
    <app-file-uploader
      [dataEditable]="true"
      [fluidWidth]="true"
      [multiple]="true"
      [uploaderType]="'IMAGE'"
      [selectedFiles]="form.controls['marketplaceVisuals'].value"
      (filesChanged)="onImagesChanged($event)"
    >
    </app-file-uploader>
  </div>
</form>
