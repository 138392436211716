import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '@services/auth/auth.service';

export const authRoleGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const authService = inject(AuthService);
  const user = authService.getUser();

  authService.destinationUrl = state.url;
  if (
    !user ||
    !user.id ||
    (user && !authService.userHasAnyRole(route.data['roles'], user.role))
  ) {
    authService.logout(true);
    return false;
  }

  return true;
};
