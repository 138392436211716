import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { PaginatorComponent } from '@modules/shared/components/paginator/paginator.component';
import { FilterComponent } from '@modules/shared/components/filter/filter.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AffiliateProductsService } from '@services/affiliate-products.service';
import { AffiliateProductDataSource } from '@ds/affiliate-product.ds';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { SearchBoxComponent } from '@modules/shared/components/search-box/search-box.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Clipboard } from '@angular/cdk/clipboard';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomDialogComponent } from '@modules/shared/dialogs/custom-dialog/custom-dialog.component';
import { AffiliateProductOverlayPageComponent } from '@modules/admin/affiliates/dialogs/affiliate-product-overlay-page/affiliate-product-overlay-page.component';
import { AffiliateProductPartner } from '@models/affiliate-products/affiliate-product-partner.enum';
import { AffiliateProductStatus } from '@models/affiliate-products/affiliate-product-status.enum';
import { AffiliateProductCreationRequest } from '@models/affiliate-products/dto/affiliate-product-creation.request';
import { AffiliateProduct } from '@models/affiliate-products/affiliate-product.model';
import { CustomDialogData } from '@models/overlay-dialogs/custom-dialog-data.model';
import { Router } from '@angular/router';
import { AppRoutes } from '../../../../../routes';
import { NotificationService } from '@services/shared/notification.service';

@Component({
  selector: 'app-affiliate-product-list',
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    PaginatorComponent,
    FilterComponent,
    MatTableModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    MatSortModule,
    SearchBoxComponent,
    MatProgressSpinnerModule,
    TranslateModule,
  ],
  providers: [AffiliateProductsService, NotificationService],
  templateUrl: './affiliate-product-list.page.html',
  styleUrl: './affiliate-product-list.page.scss',
})
export class AffiliateProductListPage
  implements OnInit, AfterViewInit, OnChanges
{
  dataSource: AffiliateProductDataSource;

  displayedColumns: string[] = [
    'select',
    'name',
    'id',
    'creationDate',
    'partner',
    'link',
    'category',
    'subcategory',
    'views',
    'clicks',
    'ctr',
    'actions',
  ];

  // @ViewChild(SearchBoxComponent) searchBox!: SearchBoxComponent;
  @ViewChild(FilterComponent) filter!: FilterComponent;
  @ViewChild(PaginatorComponent) paginator!: PaginatorComponent;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  pageSizeOptions: number[] = [5, 10, 15, 20, 50, 100]; // Options for the user to change the page size

  selection = new SelectionModel<any>(true, []);

  createNewProduct: boolean = false;

  constructor(
    private affiliateProductService: AffiliateProductsService,
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.dataSource = new AffiliateProductDataSource(
      this.affiliateProductService,
    );
  }
  ngOnInit() {
    // Set the initial page
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Check if the 'currentPage' or 'products' have changed
    this.refresh();
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.resetPageIndex();
      this.refresh();
    });

    this.refresh();
  }

  private resetPageIndex() {
    this.paginator.pager.currentPage = 1;
  }

  refresh() {
    const filters = new Map<string, any>();

    if (this.filter?.searchText) {
      filters.set('searchTerm', this.filter.searchText);
    }

    if (this.filter?.selectedStatus) {
      filters.set('status', this.filter.selectedStatus.toUpperCase());
    }

    if (this.filter?.selectedCategories.length > 0) {
      if (this.filter?.selectedSubcategories.length > 0) {
        filters.set(
          'eventSubCategoryIds',
          this.filter.selectedSubcategories.map((sc) => sc.id),
        );
      } else {
        filters.set(
          'eventCategoryIds',
          this.filter.selectedCategories.map((c) => c.id),
        );
      }
    } else {
      filters.set(
        'eventSubCategoryIds',
        this.filter.selectedSubcategories.map((sc) => sc.id),
      );
    }

    if (this.filter?.selectedPartners) {
      filters.set('partners', this.filter.selectedPartners);
    }

    if (!!this.sort.direction) {
      filters.set('sortDirection', this.sort.direction);
      filters.set('sortProperty', this.sort.active);
    }

    this.dataSource.loadAffiliateProducts(
      filters,
      this.paginator.pager.currentPage !== 0
        ? this.paginator.pager.currentPage
        : 1,
      this.paginator.pageSize,
    );
  }

  editProduct(product: any) {
    console.log('Edit:', product);
  }

  copyProduct(product: AffiliateProduct) {
    this.affiliateProductService.copy(product.id).subscribe(() => {
      this.refresh();
      this.notificationService.success(
        this.translateService.instant(
          'APP.AFFILIATE_PRODUCTS_PAGE_LIST.SUCCESS.PRODUCT_COPIED',
        ),
      );
    });
  }

  copyProductLink(productLink: string) {
    this.clipboard.copy(productLink);

    this.notificationService.success(
      this.translateService.instant(
        'APP.AFFILIATE_PRODUCTS_PAGE_LIST.SUCCESS.LINK_COPIED',
      ),
    );
  }

  pauseProduct(product: AffiliateProduct) {
    this.affiliateProductService.setActive(product.id, false).subscribe(() => {
      this.resetPageIndex();
      this.refresh();
    });
  }

  continueProduct(product: AffiliateProduct) {
    this.affiliateProductService.setActive(product.id, true).subscribe(() => {
      this.resetPageIndex();
      this.refresh();
    });
  }

  archiveProduct(product: AffiliateProduct) {
    const dto: AffiliateProductCreationRequest = {
      status: AffiliateProductStatus.ARCHIVED,
    };

    this.affiliateProductService
      .updateProduct(product.id, dto)
      .subscribe(() => {
        this.resetPageIndex();
        this.refresh();
      });
  }

  restoreProduct(product: AffiliateProduct) {
    const dto: AffiliateProductCreationRequest = {
      status: AffiliateProductStatus.ACTIVE,
    };

    this.affiliateProductService
      .updateProduct(product.id, dto)
      .subscribe(() => {
        this.resetPageIndex();
        this.refresh();
      });
  }

  deleteProduct(product: AffiliateProduct) {
    const question =
      'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.DELETE_PRODUCT_MESSAGE';
    const cancelButtonMessage =
      'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTION_BUTTONS.CANCEL_DELETE_PRODUCT';
    const deleteButtonMessage =
      'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTION_BUTTONS.CONFIRM_DELETE_PRODUCT';

    const data: CustomDialogData = {
      isDeleteDialog: true,
      question: question,
      id: product.id,
      title: product.name.toUpperCase(),
      pictureUrl: product.pictureUrl,
      partner: product?.network,
      placements: product?.affiliateProductPlacements,
      performance: {
        clicks: product.affiliateProductStats.totalAffiliateClicks,
        ctr: product.affiliateProductStats.totalAffiliateCtr,
      },
      cancelButtonMessage: cancelButtonMessage,
      deleteButtonMessage: deleteButtonMessage,
    };

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      maxWidth: '456px',
      width: '100%',
      height: 'auto',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.affiliateProductService.deleteProduct(product.id).subscribe(() => {
          this.resetPageIndex();
          this.refresh();
        });
      }
    });
  }

  onPageChange(event: PageEvent) {
    this.refresh();
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource?.data?.forEach((row) => this.selection.select(row));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data?.length;
    return numSelected === numRows;
  }

  toggleSelection(row: any) {
    this.selection.toggle(row);
  }

  onFilterChange(event: any) {
    this.resetPageIndex();
    this.refresh();
  }

  addProduct() {
    const dialogRef = this.dialog.open(AffiliateProductOverlayPageComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      panelClass: 'overlay-page-dialog',
      data: {
        // categories: this.filter.categories,
        // subcategories: this.filter.subcategories,
        allCategories: this.filter.allCategories,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filter?.onCreateNewProduct(true);
        // this.resetPageIndex();
        this.refresh();
      }
    });
  }

  getDisplayPartnerName(partner: AffiliateProductPartner) {
    return AffiliateProductPartner.getDisplayName(partner);
  }

  onRowClick(product: AffiliateProduct) {
    if (product) {
      this.router.navigate([
        '/',
        AppRoutes.Admin.root,
        AppRoutes.Admin.Affiliates.affiliates,
        product.id,
      ]);
    }
  }
}
