import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { FormsModules } from '@utils/shared-modules';
import { TranslateModule } from '@ngx-translate/core';
import { TimePickerComponent } from '@modules/shared/components/time-picker/time-picker.component';
import { EventTemplateAccess } from '@models/design-templates/event-template-access.enum';
import { EventTemplateRelevance } from '@models/design-templates/event-template-relevance.enum';
import { CategorySelectComponent } from '@modules/shared/components/category-select/category-select.component';
import { EventCategoriesService } from '@services/event-categories.service';
import { EventCategory } from '@models/event-categories/event-category.model';
import { AutocompleteSelectComponent } from '@modules/shared/components/autocomplete-select/autocomplete-select.component';
import { TargetGroup } from '@models/shared/target-group.model';
import { KeywordsSelectComponent } from '@modules/shared/components/keywords-select/keywords-select.component';
import { Keyword } from '@models/shared/keyword.model';
import { AddressPickerComponent } from '@modules/shared/component/address-picker/address-picker.component';
import { Address } from '@models/shared/address.model';
import { SharedService } from '@services/shared/shared.service';
import { TimezoneSelectComponent } from '@modules/shared/component/timezone-select/timezone-select.component';
import { TargetGroupsSelectComponent } from '@modules/shared/components/target-groups-select/target-groups-select.component';

@Component({
  selector: 'app-template-basic-infos-step',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    TimePickerComponent,
    TimezoneSelectComponent,
    KeywordsSelectComponent,
    CategorySelectComponent,
    TargetGroupsSelectComponent,
    AutocompleteSelectComponent,
    AddressPickerComponent,
  ],
  providers: [EventCategoriesService, SharedService],
  templateUrl: './template-basic-infos-step.component.html',
  styleUrl: './template-basic-infos-step.component.scss',
})
export class TemplateBasicInfosStepComponent {
  @Input() form?: FormGroup;

  accesses = EventTemplateAccess.getCreationEventTemplateAccesses();
  relevances = EventTemplateRelevance.getAllEventTemplateRelevances();

  allCategories: EventCategory[] = [];
  categories: EventCategory[] = [];
  subCategories: EventCategory[] = [];

  targetGroups: TargetGroup[] = [];
  keywords: Keyword[] = [];

  constructor(
    private eventCategoriesService: EventCategoriesService,
    private sharedService: SharedService,
  ) {
    this.eventCategoriesService
      .getEventCategories()
      .subscribe((eventCategories) => {
        this.allCategories = eventCategories;

        this.filterAndSortCategoriesAndSubcategories();

        this.form?.controls['categories'].valueChanges.subscribe((val) => {
          this.onCategoryChange(val);
        });
      });

    this.sharedService
      .getKeywords()
      .subscribe((keywords) => (this.keywords = keywords));

    this.sharedService
      .getTargetGroups()
      .subscribe((targetGroups) => (this.targetGroups = targetGroups));

    document.documentElement.style.setProperty(
      '--mat-form-field-background-color',
      '#ffffff',
    );
  }

  onRefreshCategories() {
    this.eventCategoriesService
      .getEventCategories()
      .subscribe((eventCategories) => {
        this.allCategories = eventCategories;

        this.filterAndSortCategoriesAndSubcategories();
      });
  }

  onAddressChange(address?: Address) {
    const locationAddressForm = this.form?.get('locationAddress') as FormGroup;

    if (address) {
      locationAddressForm.setValue({
        street: address.street,
        streetNumber: address.streetNumber,
        postalCode: address.postalCode,
        locality: address.locality,
        country: address.country,
        federalState: address.federalState,
        lat: address.lat,
        lng: address.lng,
        placeId: address.placeId,
      });
    } else {
      locationAddressForm.reset();
    }
  }

  filterAndSortCategoriesAndSubcategories() {
    this.categories = this.allCategories
      ?.filter((item: EventCategory) => !item.parentCategoryId)
      .sort((a, b) => a.name.localeCompare(b.name));
    this.subCategories = this.allCategories
      ?.filter((item: EventCategory) => item.parentCategoryId)
      .sort((a, b) => a.name.localeCompare(b.name));

    this.onCategoryChange(this.form?.value.categories);
  }

  onCategoryChange(val?: number[]) {
    if (val !== undefined && val.length > 0) {
      this.subCategories = this.allCategories
        .filter(
          (item: EventCategory) =>
            item.parentCategoryId && val.includes(item.parentCategoryId),
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      let selectedSubcategories: number[] = this.form?.value.subCategories;

      if (selectedSubcategories) {
        selectedSubcategories = selectedSubcategories.filter(
          (selectedCategoryId) =>
            this.subCategories
              .map((category) => category.id)
              .includes(selectedCategoryId),
        );
        this.form?.controls['subCategories'].patchValue(selectedSubcategories);
      }
    } else {
      this.subCategories = this.allCategories
        .filter((item: EventCategory) => item.parentCategoryId)
        .sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  getAccessDisplayName(access: EventTemplateAccess) {
    return EventTemplateAccess.getDisplayName(access);
  }

  getRelevanceDisplayName(relevance: EventTemplateRelevance) {
    return EventTemplateRelevance.getDisplayName(relevance);
  }
}
