import { Routes } from '@angular/router';
import { AppRoutes } from './routes';
import { LoginPage } from '@modules/auth/pages/login/login.page';
import { SignUpPage } from '@modules/sign-up/pages/sign-up/sign-up.page';
import { unauthenticatedOnlyGuard } from './auth/unauthenticated-only.guard';
import { ResetPasswordPage } from '@modules/auth/pages/reset-password/reset-password.page';
import { authRoleGuard } from './auth/auth-role.guard';
import { UserRole } from '@models/users/user-role.enum';
import { AffiliateProductListPage } from '@modules/admin/affiliates/pages/affiliate-product-list/affiliate-product-list.page';
import { AffiliateProductStatsPage } from '@modules/admin/affiliates/pages/affiliate-product-stats/affiliate-product-stats.page';
import { AffiliateProductDetailsPage } from '@modules/admin/affiliates/pages/affiliate-product-details/affiliate-product-details.page';
import { DesignTemplateListPage } from '@modules/admin/design-templates/pages/design-template-list/design-template-list.page';
import { DesignTemplateDetailsPage } from '@modules/admin/design-templates/pages/design-template-details/design-template-details.page';
import { DesignTemplateStatsPage } from '@modules/admin/design-templates/pages/design-template-stats/design-template-stats.page';
import { CampaignListPage } from '@modules/admin/campaigns/pages/campaign-list/campaign-list.page';
import { CampaignDetailsPage } from '@modules/admin/campaigns/pages/campaign-details/campaign-details.page';
import { UserListPage } from '@modules/admin/users/pages/user-list/user-list.page';
import { EventListPage } from '@modules/admin/events/pages/event-list/event-list.page';

export const routes: Routes = [
  {
    path: AppRoutes.Root.signUp,
    component: SignUpPage,
    data: { breadcrumb: 'sign-up' },
  },
  {
    path: AppRoutes.Root.login,
    component: LoginPage,
    data: { breadcrumb: 'login' },
    canActivate: [unauthenticatedOnlyGuard],
  },
  {
    path: AppRoutes.Root.forgotPassword,
    loadComponent: () =>
      import('@modules/auth/pages/forgot-password/forgot-password.page').then(
        (mod) => mod.ForgotPasswordPage,
      ),
    data: { breadcrumb: 'forgot-password' },
  },
  {
    path: AppRoutes.Root.resetPassword,
    loadChildren: () => resetPasswordRoutes,
    data: { breadcrumb: 'reset-password' },
  },
  {
    path: AppRoutes.Customer.welcome,
    loadComponent: () =>
      import('@modules/customer/pages/welcome/welcome.page').then(
        (mod) => mod.WelcomePage,
      ),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'welcome', roles: UserRole.CUSTOMER },
  },
  {
    path: AppRoutes.Customer.dashboard,
    loadComponent: () =>
      import('@modules/customer/pages/dashboard/dashboard.page').then(
        (mod) => mod.DashboardPage,
      ),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'dashboard', roles: UserRole.CUSTOMER },
  },
  {
    path: AppRoutes.Customer.eventLink,
    loadComponent: () =>
      import('@modules/event-link/pages/event-link/event-link.page').then(
        (mod) => mod.EventLinkPage,
      ),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'eventlink.bio', roles: UserRole.CUSTOMER },
  },
  {
    path: AppRoutes.Customer.eventLinkUrl,
    loadComponent: () =>
      import('@modules/event-link/pages/event-link/event-link.page').then(
        (mod) => mod.EventLinkPage,
      ),
    data: { breadcrumb: 'eventlink.bio/url' },
  },
  {
    path: AppRoutes.Customer.settings,
    loadComponent: () =>
      import('@modules/customer/pages/settings/settings.page').then(
        (mod) => mod.SettingsPage,
      ),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'settings', roles: UserRole.CUSTOMER },
  },
  {
    path: AppRoutes.Customer.profile,
    loadComponent: () =>
      import('@modules/customer/pages/profile/profile.page').then(
        (mod) => mod.ProfilePage,
      ),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'profile', roles: UserRole.CUSTOMER },
  },
  {
    path: AppRoutes.Admin.root,
    loadChildren: () => adminRoutes,
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
  {
    path: AppRoutes.Events.create,
    redirectTo: AppRoutes.Events.createEvent,
    pathMatch: 'full',
  },
  {
    path: AppRoutes.Events.createEvent,
    loadComponent: () =>
      import(
        '@modules/events/create-event/pages/create-event/create-event.page'
      ).then((mod) => mod.CreateEventPage),
    data: { breadcrumb: 'create-event' },
  },
  {
    path: AppRoutes.Events.createCampainEvent,
    loadComponent: () =>
      import(
        '@modules/events/create-wg-party/pages/create-campaign-party-page/create-campaign-party-page.component'
      ).then((mod) => mod.CreateCampaignPartyPageComponent),
    data: { breadcrumb: 'create-event/campaign' },
  },
  {
    path: AppRoutes.Events.eventByUri,
    loadComponent: () =>
      import('@modules/events/pages/event/event.page').then(
        (mod) => mod.EventPage,
      ),
    data: { breadcrumb: 'event' },
  },
  {
    path: AppRoutes.LandingPage.landingPage,
    loadComponent: () =>
      import('@modules/events/pages/event/event.page').then(
        (mod) => mod.EventPage,
      ),
    data: { breadcrumb: 'landing' },
  },
  {
    path: AppRoutes.Events.campaignEventVote,
    loadComponent: () =>
      import(
        '@modules/events/pages/campaign-event-voting/campaign-event-voting.page'
      ).then((mod) => mod.CampaignEventVotingPage),
    data: { breadcrumb: 'event' },
  },
  {
    path: AppRoutes.Events.mizunoTermsOfUse,
    loadComponent: () =>
      import(
        '@modules/events/pages/terms-of-use/mizuno-terms-of-use/mizuno-terms-of-use.component'
      ).then((mod) => mod.MizunoTermsOfUseComponent),
    data: { breadcrumb: 'terms-of-use' },
  },
  {
    path: AppRoutes.DesignTemplates.designTemplatePreview,
    loadComponent: () =>
      import(
        '@modules/design-templates/pages/design-template-preview/design-template-preview.page'
      ).then((mod) => mod.DesignTemplatePreviewPage),
    data: { breadcrumb: 'event' },
  },
  { path: '', pathMatch: 'full', redirectTo: AppRoutes.Root.login },
  { path: '**', redirectTo: '' },
];

const resetPasswordRoutes: Routes = [
  { path: '', component: ResetPasswordPage },
  { path: AppRoutes.Root.success, component: ResetPasswordPage },
];

const adminRoutes: Routes = [
  {
    path: '',
    component: AffiliateProductListPage,
  },
  {
    path: AppRoutes.Admin.Affiliates.affiliates,
    loadChildren: () => affiliateRoutes,
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
  {
    path: AppRoutes.Admin.Affiliates.affiliateStatistics,
    component: AffiliateProductStatsPage,
  },
  {
    path: AppRoutes.Admin.DesignTemplates.designTemplates,
    loadChildren: () => designTemplatesRoutes,
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
  {
    path: AppRoutes.Admin.DesignTemplates.designTemplateStatistics,
    component: DesignTemplateStatsPage,
  },
  {
    path: AppRoutes.Admin.Users.root,
    loadChildren: () => usersRoutes,
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
  {
    path: AppRoutes.Admin.Events.root,
    loadChildren: () => eventsRoutes,
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
  {
    path: AppRoutes.Admin.Campaigns.root,
    loadChildren: () => campaignsRoutes,
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
  {
    path: AppRoutes.Admin.TemplateAssets.templateAssets,
    loadComponent: () =>
      import(
        '@modules/admin/template-assets/pages/template-asset-list/template-asset-list.page'
      ).then((mod) => mod.TemplateAssetListPage),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
  {
    path: AppRoutes.Admin.prompts,
    loadComponent: () =>
      import('@modules/admin/prompts/pages/prompt-list/prompt-list.page').then(
        (mod) => mod.PromptListPage,
      ),
    canActivate: [authRoleGuard],
    data: { breadcrumb: 'admin', roles: UserRole.ADMIN },
  },
];

const affiliateRoutes: Routes = [
  {
    path: '',
    component: AffiliateProductListPage,
  },
  {
    path: ':id',
    component: AffiliateProductDetailsPage,
  },
];

const designTemplatesRoutes: Routes = [
  {
    path: '',
    component: DesignTemplateListPage,
  },
  {
    path: ':id',
    component: DesignTemplateDetailsPage,
  },
];

const usersRoutes: Routes = [
  {
    path: '',
    component: UserListPage,
  },
];

const eventsRoutes: Routes = [
  {
    path: '',
    component: EventListPage,
  },
];

const campaignsRoutes: Routes = [
  {
    path: '',
    component: CampaignListPage,
  },
  {
    path: ':uri',
    component: CampaignDetailsPage,
  },
];
