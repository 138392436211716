<div class="page-container auth-page-center">
  <div *ngIf="step === 0" class="w-100">
    <h1>{{ "APP.AUTH.LOGIN" | translate }}</h1>
    <div class="subheader subheader-sm">
      {{ "APP.AUTH.NICE_TO_SEE_YOU" | translate }}
    </div>

    <div>
      <app-login-form
        [selectedOption]="selectedOption"
        (setStepEvent)="setStep($event)"
      ></app-login-form>

      <!-- <div class="auth-fallback-container">
        <span class="text-grey"
          >{{ "APP.AUTH.FORGOT_PASSWORD" | translate }}</span
        >
        <span class="text-link" (click)="goToForgotPassword()">
          {{ "APP.AUTH.RESET_PASSWORD" | translate }}
        </span>
      </div> -->

      <div class="auth-fallback-container">
        <span class="text-grey"
          >{{ "APP.AUTH.NO_ACCOUNT_FALLBACK" | translate }}</span
        >
        <span class="text-link" (click)="goToSignUp()">
          {{ "APP.AUTH.CREATE_ACCOUNT_SHORT" | translate }}
        </span>
      </div>

      <!-- <div class="hr-text"><span>{{ "APP.OR" | translate }}</span></div>

      <app-sso-buttons></app-sso-buttons>

      <app-h-captcha-info></app-h-captcha-info> -->
    </div>
  </div>

  <div *ngIf="step === 1 && selectedOption === 'email'">
    <app-login-step2
      [email]="userEmail"
      class="auth-step-page-center"
    ></app-login-step2>
  </div>

  <div *ngIf="step === 1 && selectedOption === 'phone'">
    <app-verify-phone-dialog
      [otpUserId]="otpUserId"
      [phone]="userPhone"
      [isWrongCode]="isWrongCode"
      (changePhone)="changePhone()"
      (authLoginEvent)="authLoginEvent($event)"
    >
    </app-verify-phone-dialog>
  </div>

  @if (loginInProgress) {
  <div class="full-page-loader-cont">
    <div class="loader"></div>
  </div>
  }
</div>
